import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"
import BarGroupedLegend from "../../components/parts/chart/bar-grouped-legend"
import BarGroupedLegendFilter from "../../components/parts/chart/bar-grouped-legend-filter"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s10_2_2 : false,
      s10_2_3 : false,
      s10_2_4 : false,
      s10_2_6 : false
    }

    this.searchInit = {
      filter1Init: 4,
      filter2Init: 3,
      filter3Init: 'technology-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarGroupedLegend id="co2SeparateStorage" chartData="co2SeparateStorage" width="100%" height="420px" />
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>CO<sub>2</sub>貯留コスト（現状・米国）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s10_2_2"}>
                <img src={'../../images/chart/s10-2-2.png'} alt="CO2貯留コスト（現状・米国）" width={559} />
              </button>
              <ImageBox
                open={this.state.s10_2_2}
                close={this.closeModal}
                name={"s10_2_2"}
                src={'/images/chart/s10-2-2.png'}
                alt="CO2貯留コスト（現状・米国）"
                width={559}
                height={401} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/ccus-in-clean-energy-transitions" target="_blank" rel="noreferrer noopener">IEA（2020）"Energy Technology Perspectives 2020 - Special Report on Carbon Capture Utilisation and Storage"を基に作成</a></p>
                <p className={chartStyles.chartExplain}>米国における地中貯留、海底貯留のCO<sub>2</sub>貯留コストの割合を示す<br />
                Onshore：地中貯留<br />
                Offshore：海底貯留</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>炭素税・排出量取引制度の導入国・地域</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s10_2_3"}>
                <img src={'../../images/chart/s10-2-3.png'} alt="炭素税・排出量取引制度の導入国・地域" width={559} />
              </button>
              <ImageBox
                open={this.state.s10_2_3}
                close={this.closeModal}
                name={"s10_2_3"}
                src={'/images/chart/s10-2-3.png'}
                alt="炭素税・排出量取引制度の導入国・地域"
                width={1164}
                height={631} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://openknowledge.worldbank.org/bitstreams/3b81ebd1-365b-5238-967d-053a2754f166/download" target="_blank" rel="noreferrer noopener">World Bank（2022）"State and Trends of Carbon Pricing 2022"を基に作成</a></p>
                <p className={chartStyles.chartExplain}>炭素税・排出量取引制度の導入国・地域を示す</p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>排出量取引制度のこれまでの価格推移</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s10_2_4"}>
                <img src={'../../images/chart/s10-2-4.png'} alt="排出量取引制度のこれまでの価格推移" width={559} />
              </button>
              <ImageBox
                open={this.state.s10_2_4}
                close={this.closeModal}
                name={"s10_2_4"}
                src={'/images/chart/s10-2-4.png'}
                alt="排出量取引制度のこれまでの価格推移"
                width={975}
                height={465} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://openknowledge.worldbank.org/bitstreams/3b81ebd1-365b-5238-967d-053a2754f166/download" target="_blank" rel="noreferrer noopener">World Bank（2022）"State and Trends of Carbon Pricing 2022"を基に作成</a></p>
                <p className={chartStyles.chartExplain}>各エリア別の排出量取引制度のこれまでの価格推移を示す</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarGroupedLegendFilter id="co2SeparateCarbonPrice" chartData="co2SeparateCarbonPrice" defaultSelect="steps" width="100%" height="420px"  />
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>CO<sub>2</sub>貯留ポテンシャル（国別）</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s10_2_6"}>
                <img src={'../../images/chart/s10-2-6.png'} alt="CO<sub>2</sub>貯留ポテンシャル（国別）" width={559} />
              </button>
              <ImageBox
                open={this.state.s10_2_6}
                close={this.closeModal}
                name={"s10_2_6"}
                src={'/images/chart/s10-2-6.png'}
                alt="CO2貯留ポテンシャル（国別）"
                width={1029}
                height={613} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.ogci.com/wp-content/uploads/2023/04/CSRC_Cycle_3_Main_Report_Final.pdf" target="_blank" rel="noreferrer noopener">OGCI, “「CO2 Storage Resource Catalogue 3 Report” を基に作成</a></p>
                <p className={chartStyles.chartExplain}>国別のCO<sub>2</sub>貯留能力を示す<br />
                Sub-Commercial：既に発見済でアクセス可能だが事業開発に至っていないサイト<br />
                Undiscoverd： 貯蔵の適性が確認されていない総貯蔵リソースの推定量</p>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`